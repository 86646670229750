<template>
  <v-select
    v-model="roundBy"
    :items="items"
    :label="$t('currency.round')"
    :loading="loading"
    dense
    hide-details
    outlined
    @change="onChange"
  />
</template>

<script lang="ts">
import type {
  CurrencyCompanyPivot,
  CurrencyRate,
  CurrencyRateData,
} from "@planetadeleste/vue-mc-gw";
import type { CurrencyData } from "@planetadeleste/vue-mc-shopaholic";
import { Currency } from "@planetadeleste/vue-mc-shopaholic";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ConfigModule } from "@/store/config";
import { AppModule } from "@/store/app";
import { find, isEmpty, isNil, set } from "lodash";
import useCompanyHelper from "@/composables/company";
import { number } from "mathjs";

@Component
export default class CurrencyRateRoundSelect extends Vue {
  @Prop(Number) readonly companyId!: number;
  @Prop(Object) readonly currency!: Partial<CurrencyData> | Currency;
  @Prop(Object) readonly currencyRate!:
    | Partial<CurrencyRateData>
    | CurrencyRate;

  obCurrency: Currency | null | undefined = null;
  loading = false;

  get items() {
    return [
      { text: this.$t("without.rounded"), value: 0 },
      { text: "0.01", value: 0.01 },
      { text: "0.10", value: 0.1 },
      { text: "1.00", value: 1 },
      { text: "10.00", value: 10 },
      { text: "100.00", value: 100 },
    ];
  }

  get roundBy(): number {
    return number(this.obCurrency?.get("pivot.round", 0) ?? 0);
  }

  set roundBy(sValue: number) {
    this.setRound(sValue);
  }

  setRound(sValue: number) {
    if (!this.obCurrency) {
      return;
    }

    const obPivot = this.obCurrency.get(
      "pivot",
      {}
    ) as Partial<CurrencyCompanyPivot>;
    set(obPivot, "round", sValue);
    this.obCurrency.set("pivot", obPivot);
  }

  async mounted() {
    await this.load();
  }

  onChange(fValue: number) {
    if (!this.obCurrency) {
      return;
    }

    this.setRound(fValue);
    this.$emit("change", fValue);
    this.save();
  }

  async load() {
    if (!this.companyId || !this.currency?.id) {
      return;
    }

    if (this.companyId === AppModule.companyId) {
      const arCompanyCurrencies = ConfigModule.companyCurrencies;
      const obModel = find(arCompanyCurrencies, { id: this.currency.id });

      if (obModel) {
        this.obCurrency = obModel;
        return;
      }
    }

    this.loading = true;
    const obCompanyHelper = useCompanyHelper(this.companyId);
    const arCurrencies = await obCompanyHelper.loadCurrencies();
    const obCurrencyData = find(arCurrencies, { id: this.currency.id });

    if (obCurrencyData) {
      this.obCurrency = new Currency(obCurrencyData as CurrencyData);
    }
    this.loading = false;
  }

  async save() {
    // Get out if CurrencyRate is not passed as prop
    if (!this.obCurrency) {
      return;
    }

    this.loading = true;
    const obCompanyHelper = useCompanyHelper(this.companyId);
    const obPivotData = this.obCurrency.get("pivot") as
      | CurrencyCompanyPivot
      | undefined;
    const obData: CurrencyCompanyPivot =
      isEmpty(obPivotData) || isNil(obPivotData)
        ? {
            currency_id: this.obCurrency.id,
            company_id: this.companyId,
            active: true,
          }
        : obPivotData;
    await obCompanyHelper.addUpdateCurrency(obData as CurrencyCompanyPivot);

    if (this.companyId === AppModule.company.id) {
      await ConfigModule.loadCompanyCurrencies(true);
    }

    this.loading = false;
  }
}
</script>
